<template>
  <div class="desktopLayoutClass">
      <v-app style=" padding: 5px;">
        <!-- Dialog Start -->
    <div>
      <v-row justify="center">
        <v-dialog v-model="auth_error_enable" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
              {{$t("Warning_Message.Unauthorized")}}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeDialogUnauthorized">
                 {{$t("Warning_Message.Ok")}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <div>
      <v-row justify="center">
        <v-dialog v-model="error_enable" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
               {{$t("Warning_Message.Something_Went_Wrong")}}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeDialogError">
                {{$t("Warning_Message.Ok")}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <div>
      <v-row justify="center">
        <v-dialog v-model="no_appointment_error" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
            {{$t("Warning_Message.Nat_A_Valid_Appointment")}}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeAppointmentError">
                {{$t("Warning_Message.Ok")}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <!-- Dialog Close -->
      <div>
        <div style="display: flex; flex-direction: row;">
          <div style="display: flex; flex-direction: row; width: 100%; " >

            <div style="width: 100%;" align="center">
                <br>
                <span style="font-weight: 600;font-size: 15pt;margin-top: 10px;">{{ $t("Customer.BookedSuccess.Doctor_Consultation_Confirmation") }}</span>
            </div>
          </div>
        </div>
        <br>

        <div style="display: flex;flex-direction: column; padding: 10px; " align="left" v-if="!isLoadingBookingDetails">
          <div align="center">
            <img style="height:48px; width: 48px;" src="https://s3iconimages.mymedicine.com.mm/success_tick.svg"/>
            <br>
            <span style="size: 16px;font-weight: 500;line-height: 24px;  font-style: normal">Booking ID: {{book_id}}</span>
            <br>
            <span style="size:16px; line-height: 24px; font-style: normal">{{ $t("Customer.BookedSuccess.Appointment_has_been_successfully_booked") }}</span>
          </div>
          <!-- <br> -->

          <!-- SMS Remainder -->
          <v-card class="my-2 rounded-lg" flat style="background-color: #FFCC4D;">
            <div class="d-flex justify-space-between align-center my-2 px-1">
              <img style="height:48px; width: 48px;" src="https://s3iconimages.mymedicine.com.mm/note_icon.png"/>

              <p class="ma-0 px-2" style="font-size: 14px;">{{ $t("Customer.BookedSuccess.Note_Description") }}</p>
            </div>
          </v-card>
          <!-- SMS Remainder -->

          <v-card style="background-color:#1467BF;color: white; border-radius: 8px; box-shadow:0px 0px 0px 0px !important">
            <div style='display: flex; flex-direction: row; padding: 10px;'>
              <div  style="flex:1;">
               <span class="css-title"> {{ $t("Customer.BookedSuccess.Name") }}:</span>
              </div>
              <div  style="flex:1;text-align: right;">
                <span class="content">{{nominee}}</span>
              </div>
            </div>
            <v-divider/>
            <div style='display: flex; flex-direction: row; padding: 10px;'>
              <div  style="flex:1;">
               <span class="css-title"> {{ $t("Customer.BookedSuccess.Specialization") }}:</span>
              </div>
              <div  style="flex:1;text-align: right;">
                <span class="content">{{specialization}}</span>
              </div>
            </div>
            <v-divider/>
            <div style='display: flex; flex-direction: row; padding: 10px;'>
              <div  style="flex:1;">
               <span class="css-title"> {{ $t("Customer.BookedSuccess.Doctor") }}:</span>
              </div>
              <div  style="flex:1;text-align: right;">
                <span class="content">{{doctor_name}}</span>
              </div>
            </div>
            <v-divider/>
            <div style='display: flex; flex-direction: row;  padding: 10px'>
              <div  style="flex:1;">
               <span class="css-title"> {{ $t("Customer.BookedSuccess.Date") }}:</span>
              </div>
              <div  style="flex:1;text-align: right;">
                <span class="content">
                   <DateRendered :dateVal="date"> </DateRendered>
                  </span>
              </div>
            </div>
            <v-divider/>
            <div style='display: flex; flex-direction: row;  padding: 10px'>
              <div  style="flex:1;">
               <span class="css-title"> {{ $t("Customer.BookedSuccess.Time") }}:</span>
              </div>
              <div  style="flex:1;text-align: right;">
                <span class="content"><TimeRender :timeSlot="time_slot"> </TimeRender></span>
              </div>
            </div>
            <v-divider/>
            <div style='display: flex; flex-direction: row;  padding: 10px'>
              <div  style="flex:1;">
                 <span class="css-title" v-if="payment_status=='PAID'"> {{ $t("Customer.BookedSuccess.Amount") }}:</span>
                 <span class="css-title" v-else> Payment: </span>
              </div>
              <div v-if="payment_status=='PAID'"  style="flex:1;text-align: right;">
                <span class="content">{{amount}} {{ $t("Customer.BookedSuccess.MMK") }}</span>
              </div>
              <div v-else  style="flex:1;text-align: right;">
                <span class="content">{{payment_status}}</span>
              </div>
            </div>
            <v-divider/>
            <div style='padding: 10px'>
              <div>
               <span class="css-title"> {{ $t("Customer.BookedSuccess.Description") }}:</span>
              </div>
              <div class="descriptionClass">
                <span class="content">{{description}}</span>
              </div>
            </div>
          </v-card>
        </div>
        <div algin="center" v-else>
            <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>

        </div>
        <!-- commented for hide verify devices -->
        <!-- <div>
          <div v-if="videoCallVerifiedStatus || $store.state.user_type == 'APP'">
          <div style="width:100%;padding: 10px;" v-if="!isLoadingBookingDetails">
            <div v-if="!videoCallVerifiedStatus" style="padding: 10px 0px;">
              <img style="vertical-align: middle;" src="https://s3iconimages.mymedicine.com.mm/info.svg" />
              <a @click="redirectToVideoCallTestPage">{{ $t("Customer.BookedSuccess.Verify_Request") }}</a>
            </div>
            <v-btn v-if="book_id != 0" style="width:100%; min-height: 44px;" @click="redirectToHomePage()" color="primary"><span style=" font-weight: 600; font-size: 16px; line-height: 24px;">{{ $t("Customer.BookedSuccess.Home") }}</span></v-btn>
          </div>
          </div>

          <div v-else>
          <div style="width:100%;padding: 10px;" v-if="!isLoadingBookingDetails">
            <br>
            <v-btn v-if="book_id != 0" style="width:100%; min-height: 44px;" @click="redirectToTestPage()" color="primary"><span style=" font-weight: 600; font-size: 16px; line-height: 24px;">{{ $t("Customer.BookedSuccess.Test") }}</span></v-btn>
          </div>
          </div>
          <br>
          <br>
        </div> -->
         <div>
          <div style="width:100%;padding: 10px;" v-if="!isLoadingBookingDetails">
             <v-btn v-if="book_id != 0" style="width:100%; min-height: 44px;" @click="redirectToHomePage()" color="primary"><span style=" font-weight: 600; font-size: 16px; line-height: 24px;">{{ $t("Customer.BookedSuccess.Home") }}</span></v-btn>
          </div>
          <br>
          <br>
        </div>
        </div>
      </v-app>


  </div>
</template>

<script>
import NavBar from '../../../views/navbar.vue';
import axios from "axios";
import bottomnavbar from './bottomnavbar.vue';
import DateRendered from './dateRender.vue';
import TimeRender from './TimeRender.vue';
export default {
  name: 'BookedSuccess',
  data() {
    return {
      auth_error_enable: false,
      error_enable: false,
      booking_id: "",
      nominee: "",
      success_flag: 0,
      date: "",
      time_slot: "",
      specialization: "",
      doctor_name: "",
      amount: 0,
      link_generated: true,
      payment_status: "",
      book_id: 0,
      booking_details: "",
      feedback: false,
      epoch_app_time: "",
      enquiry_name: "",
      description: "",
      notes: "",
      currentCustomer:"",
      // customer_id:"",
      customer_name:"",
      pageName : "BookedSuccess",
      isMobile : false,
      no_appointment_error: false,
      isLoadingBookingDetails:true,
      //  videoCallVerifiedStatus:"",
    };
  },
  components: {
    NavBar,
    bottomnavbar,
    DateRendered,
    TimeRender
  },
  methods: {
    redirectToVideoCallTestPage(){
       this.$router.push({
        name: "VideoCallTestPage",
      });
    },
    closeAppointmentError(){
      this.no_appointment_error = false;
      this.$router.push({
        name:"CustomerHomeScreen"
      });
    },
    closeDialogUnauthorized(){
      this.auth_error_enable = false;
      this.$router.push({
        name: "Login",
      });
    },
    closeDialogError(){
    this.error_enable = false;
    },
    redirectToHomePage()
    {
        this.$router.push({
          name: "CustomerHomeScreen"
      })
    },
    redirectToTestPage()
    {
        this.$router.push({
          name: "VideoCallTestPage"
      })
    },
    onClick(e){
        var createClickLogBody = {
          token: this.currentCustomer,
          typeOfUser: 'CUSTOMER',
          clientX: String(e.clientX),
          clientY: String(e.clientY),
          pageName: 'BookedSuccess'
        }
        axios.post(process.env.VUE_APP_BACKEND_URL+"/createClickLog",createClickLogBody)
        .then((createClickLogResponse)=>{
          // console.log('createClickLogResponse', createClickLogResponse)
        })
        .catch((createClickLogError) => {

        });
    },
    insertAppointmentType(){
      let appointmentType = localStorage.getItem('bookingType');
      let appointmentTypeTableBody = {
        token: this.currentCustomer,
        typeOfAppointment: appointmentType,
        typeOfUser: 'CUSTOMER'
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+'/appointmentTypeTable', appointmentTypeTableBody)
      .catch((error) => {
        console.log('[ERROR] Error in updating appointment type :', error);
      });
    },
    userPageViewTable() {
            if (this.currentCustomer === null) {
                var userPageViewTableBody = {
                pageName : 'bookSuccessPage',
                typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                pageName: 'bookSuccessPage',
                typeOfUser: 'CUSTOMER',
                token: this.currentCustomer
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
    },
  },
  mounted() {
    this.isMobile = this.$device.mobile;
    if(this.$store.state.locale == 'en')
      document.title = "Booking Confirmation"
    else
      document.title = 'ဘွတ်ကင်အတည်ပြုခြင်း'
    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.currentCustomer = this.$cookies.get("customerToken");
		if(!this.currentCustomer){
			this.$router.push({
				name : "Login"
			})
		}
		else{

      //logging page hit - start
      let x =  localStorage.getItem("currentURL")
      let pageName = "BookedSuccessScreen";
      if( x == pageName)
        x = "";
      localStorage.setItem("currentURL", pageName)
      var pageAccessBody = {
        token: this.currentCustomer,
        pageName: pageName,
        previousPage: x ? x : "START"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/logUserAPIInvocations", pageAccessBody)
      .then((logSuccess) =>{

      })
      .catch((logError) =>{

      });
      //logging page hit - end

      var params = this.$route.params;
      var booking_id = this.$route.query.merch_order_id;
      var userAgent = navigator.userAgent
      this.booking_id = booking_id;
      if (!this.booking_id.startsWith('epharmacy') && !this.booking_id.startsWith('NBA')) {
        var viewParticularAppointmentPageData = {
          // userAgent: userAgent,
          "token" : this.currentCustomer,
          "bookingId" : this.booking_id,
          "typeOfUser" : "CUSTOMER"
        }
        axios.post(process.env.VUE_APP_BACKEND_URL + "/bookedSuccessPage",viewParticularAppointmentPageData)
        .then((viewParticularAppointmentPageResponse) => {
            this.$i18n.locale = viewParticularAppointmentPageResponse.data.customer.language;
            // this.customer_id = viewParticularAppointmentPageResponse.data.customer._id;
            this.customer_name = viewParticularAppointmentPageResponse.data.customer.customer_name;
            this.booking_details =  viewParticularAppointmentPageResponse.data.data;
            // this.videoCallVerifiedStatus = viewParticularAppointmentPageResponse.data.user_config_data.verified_status;
            if(this.booking_details)
            {
              this.book_id = this.booking_details.appointment_reference_number;
              this.payment_status = this.booking_details.payment_status;
              this.nominee = this.booking_details.booked_for_name;
              this.date = this.booking_details.date_of_appointment;
              this.time_slot = this.booking_details.time_slot;
              this.amount = this.booking_details.amount;
              // this.enquiry_name = this.booking_details.enquiry_type_name;
              this.description = this.booking_details.description;
              this.epoch_app_time = this.booking_details.booking_epoch_time;
              // this.notes = this.booking_details.doctor_notes;
              this.specialization = this.$i18n.locale == 'mm' ? this.booking_details.specialization_name_mm : this.booking_details.specialization_name;
              this.doctor_name = this.$i18n.locale == 'mm' ? this.booking_details.doctor_name_mm : this.booking_details.doctor_name;
              var epoch_now = new Date().getTime();
              if((this.epoch_app_time - epoch_now) > 600000)
              {
                this.link_generated = false;
              }
              else
              {
                this.link_generated = true;
              }
            }
            else
            {
              this.no_appointment_error = true;
            }
            this.isLoadingBookingDetails = false
            this.insertAppointmentType();
            this.userPageViewTable();
        })
        .catch((viewParticularAppointmentPageError)=>{
          if(viewParticularAppointmentPageError.response.status == 401){
            this.auth_error_enable = true;
             this.isLoadingBookingDetails = false
          }
          else{
            this.error_enable = true;
             this.isLoadingBookingDetails = false
          }
        });
      } else if (this.booking_id.startsWith('epharmacy')) {
        this.$router.push({
          path: '/customer/orderSuccess?merch_order_id=' + this.booking_id
        });
      } else if (this.booking_id.startsWith('NBA')) {
        this.$router.push({
          path: `/nurse/AppointmentSuccessPage/${this.booking_id.split('_')[1]}`
        });
      }
  	}
  }
}
</script>

<style scoped>
.theme--light.v-divider
{
  border: 1px solid #4099CE !important;
  width: 95%;
  margin: auto;
}
.css-title
{
  font-weight: 300;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
}

.content
{
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
  color: #ffffff;
  line-height: 24px;
}

.descriptionClass::-webkit-scrollbar-track {
  background: #1467BF;
}

/* Handle */
.descriptionClass::-webkit-scrollbar-thumb {
  background: #1467BF;
}

/* Handle on hover */
.descriptionClass::-webkit-scrollbar-thumb:hover {
  background: #1467BF;
}
.descriptionClass
{
  text-align: left;
  height: 10vh;
  overflow: scroll;
  overflow-x:  hidden;
}

/* @media (min-width: 800px){
  .bookSuccessClass{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }} */
</style>